.required {
  color: $warning;
  font-weight: 600;
}

.form-group {
  margin-bottom: .6rem;
}

.form-control {
  max-height: 38px;
  font-family: 'Titillium Web', sans-serif;
}

textarea.form-control {
  max-height: inherit;
}

.field-output {
  font-weight: 600;
  line-height: 1.3rem;
  color: $secondary;
}

.has-error {
  &.checkbox label,
  &.checkbox-inline label,
  &.radio label,
  &.radio-inline label,
  .checkbox,
  .checkbox-inline,
  .control-label,
  .help-block,
  .radio,
  .radio-inline {
    color: $warning;
  }

  .form-control {
    border-color: $warning;
    -webkit-box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
    box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
  }
}

.form-check-inline .form-check-input {
  margin-right: .6rem;
}

.form-control:focus {
  border-color: $primary;
}

// Datepicker overrides
.datepicker-panel {
  > ul > li {
    &.picked,
    &.picked:hover,
    &.picked.highlighted  {
      background-color: lighten($secondary, 40%);
      color: $secondary;
      font-weight: bold;
    }

    &.highlighted {
      color: $primary;
      font-weight: bold;
      background: none;
    }

    &:hover {
      background-color: lighten($secondary, 40%);
    }
  }
}

// Styles for form preview

.preview-mode {
  .panel-body {
    h4 {
      border-bottom: 2px solid lighten($grey, 50%);
      margin-top: .3rem;
      margin-bottom: .6rem;
      padding-bottom: .8rem;
    }

    .help {
      display: none;
    }
  }

  .control-label {
    font-weight: 600;
  }

  .form-group.row {
    align-items: baseline;
  }

  .form-control {
    background: white;
  }

  span.required {
    display: none;
  }

  select::-ms-expand {
    display: none;
  }

  select {
    -webkit-appearance: none;
    -moz-appearance: none;
    text-indent: 1px;
    text-overflow: '';
  }
}

.chosen-container-multi, 
.chosen-container {
  width: 100% !important;
  
  .chosen-choices,
  .chosen-single {
 
    display: block;
    background: white;
    padding: .28rem .75rem;
    font-size: 1rem;
    line-height: 1.4;
    color: #495057;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    box-shadow: none;
    border-radius: .25rem;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;

    div b {
      background-position: 0 9px;
    }

    .search-choice {
      font-size: 14px;
    }
  }

  .chosen-single {
    height: 38px;

    span {
      line-height: 1.8;
    }
  }
}

.chosen-container-active .chosen-choices {
  border-color: #EC1F29;
  box-shadow: 0 0 0 0.2rem rgba(0,123,255,.25)
}

.input-group-append {
  .btn {
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
  }
}

input[type="file"] {
  font-size: 13px;
  padding-left: 7px;
}