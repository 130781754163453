.body {
  margin-top: 3rem;

  .blok {
    margin-bottom: 3rem;

    h3 {
      color: $secondary;
    }

    a {
      color: $base;
      text-decoration: none;

      &:hover {
        color: $secondary;
      }
    }

    &.bg--grey {
      color: $white;
      background: $secondary;

      h3 {
        color: $white;
      }
    }

    p {
      min-height: 3rem;
    }

    img {
      max-width: 100%;
      margin-bottom: 1rem;

    }

    ul {
      margin-left: 0;
      margin-bottom: 0;
      padding: 0;
    }

    label {
      width: 3rem;
    }

    li {
      list-style: none;
      padding-left: 2rem;
      margin-bottom: .5rem;
    }
  }
}

dl {
  margin-bottom: 0;

  dt, dd {
    display: inline-block;
    margin-bottom: 0;
    margin-top: .5rem;
  }

  dt {
    min-width: 5rem;
  }
}

.container {
  max-width: 1400px;
}