header {
  .logotip img {
    padding: 1rem 0;
    max-width: 220px;
  }

  .link {
    color: $primary;
  }
}

.main-navbar {
  display: flex;
  align-items: center;
  min-height: 50px;
}

.main-menu {
  background: $primary;
}

.user-menu {
  margin: 0;
  display: none;
  flex-flow: column wrap;

  @media screen and (min-width: 48em) {
    display: flex !important; 
    flex-flow: row wrap;
  }

  li {
    color: $white;
    margin: 0;
    list-style: none;

    span {
      display: block;
      padding: 5px .8rem;

      @media screen and (min-width: 48em) {
        padding: 12px 10px;
      }
    }
  }

  a {
    color: $white;
    display: block;
    text-decoration: none;
    padding: 5px .8rem;

    @media screen and (min-width: 48em) {
      padding: 14px 10px;
    }

    &:hover {
      color: $white;
      background-color: $grey;
    }
  }
}

.menu {
  margin: 0;
  padding: 0;
  display: none;
  flex-flow: column wrap;

  @media screen and (min-width: 48em) {
    display: flex !important; 
    flex-flow: row wrap;
  }

  a {
    text-decoration: none;
    padding: 5px .8rem;

    @media screen and (min-width: 48em) {
      padding: 14px 12px;
    }
  }

  li {
    margin: 0;
    list-style: none;
  }

  > li {
    font-weight: bold;

    a {
      display: block;
      color: $white;
    }

    > .sub-menu {
      padding: 0;
      transition: opacity ease .2s, visibility ease .3s;
      display: none;

      li {
        font-weight: normal;
        text-indent: 1.5rem;
      }

      @media screen and (min-width: 48em) {
        display: block;
        opacity: 0;
        visibility: hidden;
        position: absolute;

        li {
          text-indent: 0;
        }
      }
    }

    &:hover {
      .sub-menu {
        visibility: visible;
        opacity: 1;
      }
    }

    &.active {
      > a {
        text-decoration: none;
        background: $grey;
      }
    }

     &:hover {
      > a {
        position: relative;
        z-index: 11;
        text-decoration: none;
        background: $secondary;
      }
    }

    &:hover > .sub-menu {
      background: $secondary;
      box-shadow: 2px 2px 15px rgba(0,0,0,0.3);
      z-index: 10;

      li > a {
        color: $white;
        padding: 8px 10px;

        &:hover {
          text-decoration: none;
          background: lighten($secondary, 10%);
        }
      }
    }
  }
}

.placeholder {
  min-height: 40px;
}

.heading {

  .col {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .vloga {
    font-weight: bold;
    background: $light-grey;
    padding: 5px 15px;
    border-radius: 3px;
    vertical-align: text-bottom;
    margin: 5px 0 0 20px;
  }

  h1 {
    font-size: 1.5rem;
    display: flex;
    font-weight: 600;
    align-self: center;
    margin-bottom: 0;
    color: $white;
    margin-bottom: 1rem;
    display: inline-block;

    &.icon {
      background-size: 30px;
      padding-left: 3rem;
    }

    @media screen and (min-width: 48em) {
      margin-top: 1rem;
      margin-bottom: 1rem;
      font-size: 2rem;

      &.icon {
        background-size: auto;
        padding-left: 4rem;
      }
    }
  }
}

i[class*='icon'] {
  display: inline-block;
  vertical-align: sub;
  width: 20px;
  height: 21px;
}

#mobile-icon {
  background: $primary;
  position: absolute;
  padding: 8px 12px;
  top: 0px;
  right: 0px;
  color: white;
  font-size: 2rem;
  margin-right: 0;
  z-index: 501;

  @media screen and (min-width: 48em) {
    display: none;
  }

  .line {
    width: 30px;
    height: 4px;
    background-color: #ecf0f1;
    display: block;
    margin: 5px auto;
    transition: all 0.3s ease-in-out;
  }

  &.opened {
    .line:nth-child(2) {
      opacity: 0;
    }

    .line:nth-child(1) {
      transform: translateY(11px) rotate(45deg);
    }

    .line:nth-child(3) {
      transform: translateY(-7px) rotate(-45deg);
    }
  }

  &:hover {
    cursor: pointer;
  }
}