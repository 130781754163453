@import url("https://fonts.googleapis.com/css?family=Titillium+Web:400,600");
.bg--green {
  background-color: #40474A;
}

.bg--blue {
  background-color: #5788B3;
}

.bg--grey {
  background-color: #40474A;
}

.bg--dark-grey {
  background-color: #40474A;
}

.bg--light-grey {
  background-color: #EEF2F5;
}

h3 {
  font-size: 1.5rem;
  font-weight: 600;
  color: #EC1F29;
}

h4 {
  font-size: 1.3rem;
  font-weight: 600;
  color: #40474A;
  margin: 1rem 0;
}

.margin-top {
  margin-top: 2rem;
}

@media screen and (min-width: 48em) {
  .skip-field {
    margin-top: 3.2rem;
  }
}

@media screen and (min-width: 48em) {
  .offset-top {
    margin-top: -5rem;
  }
}

.display-block {
  display: block;
}

.padding {
  padding: 1.2rem 1.5rem;
}

.padding-v {
  padding: 1.5rem 0;
}

.tooltip {
  font-size: 0.8rem;
}

.modal .modal-content {
  border: 1px solid #EC1F29;
  border-radius: 0;
}

.modal .modal-header {
  background-color: #EC1F29;
  padding: 5px 15px;
  color: #fff;
  font-weight: 600;
  border-bottom: 0;
  border-radius: 0;
}

.modal .modal-header .close {
  color: #fff;
  font-weight: 300;
}

.modal .modal-header .icon {
  padding-left: 2.5rem;
}

.modal .modal-header i[class*='icon'] {
  background-size: 66%;
  padding-right: 0;
  width: 26px;
  height: 26px;
}

.modal .modal-body {
  padding: 2rem 2rem .5rem;
}

@media (min-width: 576px) {
  .modal .modal-dialog {
    max-width: 600px;
    margin: 15% auto 0 auto;
  }
}

.modal .modal-footer {
  border: none;
}

.modal .modal-footer > :not(:first-child) {
  margin-left: 1rem;
}

.light {
  line-height: .5;
  padding: 0 5px 0 15px;
  color: #EC1F29;
}

.light.on {
  color: #80BB27;
}

.light:before {
  font-size: 1.3rem;
  content: "\25CF";
}

.alert .form-group:last-of-type {
  margin-bottom: 0;
}

.pager-listview td {
  padding: 0.9rem 0.6rem !important;
}

.pager-listview:hover {
  background-color: transparent !important;
}

.pager-listview-item,
.pager-listview-item-selected {
  background: #fff;
  padding: 3px 10px;
  margin: 0 8px 0 0;
  border: 1px solid #EEF2F5;
}

.pager-listview-item a {
  cursor: pointer;
}

.pager-listview-item:hover {
  background: #EEF2F5;
}

.pager-listview,
.pager-listview:hover {
  background-color: transparent !important;
}

.pager-listview-item-selected {
  border-color: #EC1F29;
  background: #EC1F29;
  color: #fff;
}

.hover-alert-bottom {
  z-index: 1000;
  position: fixed;
  bottom: 30px;
  left: 50%;
  transform: translateX(-50%);
}

.btn {
  border-radius: 0;
  text-decoration: none;
  border: none;
}

.btn i {
  padding-right: 2rem;
}

.btn:focus {
  box-shadow: none;
}

.dropdown-toggle {
  border: 1px solid #ced4da;
  border-radius: .25rem;
}

.bootstrap-select.btn-group .dropdown-menu {
  box-shadow: 2px 5px 15px rgba(0, 0, 0, 0.3);
}

.dropdown-menu.show .dropdown-menu {
  display: block;
}

.dropdown-menu.show .dropdown-menu li a {
  display: block;
  padding: 2px 10px;
}

.dropdown-menu.show .dropdown-menu li.selected a {
  color: #EC1F29;
}

.margin-left {
  margin-left: 1rem;
}

.btn-default {
  background: #EC1F29;
  color: #fff;
}

.btn-default:focus {
  color: #fff;
}

.btn-default:hover {
  color: #fff;
  background-color: #c7111a;
}

.btn-secondary {
  background: #5788B3;
  color: #fff;
}

.btn-secondary:hover {
  color: #fff;
  background-color: #436e94;
}

.btn-inverted {
  background: #fff;
  border: 1px solid #EC1F29;
  color: #EC1F29;
}

.btn-inverted:focus {
  color: #5788B3;
}

.btn-inverted:hover {
  background-color: #EEF2F5;
  color: #EC1F29;
}

.btn-secondary-inverted {
  background: #fff;
  border: 1px solid #5788B3;
  color: #5788B3;
}

.btn-secondary-inverted:hover {
  background-color: #EEF2F5;
  color: #5788B3;
}

.pointer {
  cursor: pointer;
}

header .logotip img {
  padding: 1rem 0;
  max-width: 220px;
}

header .link {
  color: #EC1F29;
}

.main-navbar {
  display: flex;
  align-items: center;
  min-height: 50px;
}

.main-menu {
  background: #EC1F29;
}

.user-menu {
  margin: 0;
  display: none;
  flex-flow: column wrap;
}

@media screen and (min-width: 48em) {
  .user-menu {
    display: flex !important;
    flex-flow: row wrap;
  }
}

.user-menu li {
  color: #fff;
  margin: 0;
  list-style: none;
}

.user-menu li span {
  display: block;
  padding: 5px .8rem;
}

@media screen and (min-width: 48em) {
  .user-menu li span {
    padding: 12px 10px;
  }
}

.user-menu a {
  color: #fff;
  display: block;
  text-decoration: none;
  padding: 5px .8rem;
}

@media screen and (min-width: 48em) {
  .user-menu a {
    padding: 14px 10px;
  }
}

.user-menu a:hover {
  color: #fff;
  background-color: #40474A;
}

.menu {
  margin: 0;
  padding: 0;
  display: none;
  flex-flow: column wrap;
}

@media screen and (min-width: 48em) {
  .menu {
    display: flex !important;
    flex-flow: row wrap;
  }
}

.menu a {
  text-decoration: none;
  padding: 5px .8rem;
}

@media screen and (min-width: 48em) {
  .menu a {
    padding: 14px 12px;
  }
}

.menu li {
  margin: 0;
  list-style: none;
}

.menu > li {
  font-weight: bold;
}

.menu > li a {
  display: block;
  color: #fff;
}

.menu > li > .sub-menu {
  padding: 0;
  transition: opacity ease .2s, visibility ease .3s;
  display: none;
}

.menu > li > .sub-menu li {
  font-weight: normal;
  text-indent: 1.5rem;
}

@media screen and (min-width: 48em) {
  .menu > li > .sub-menu {
    display: block;
    opacity: 0;
    visibility: hidden;
    position: absolute;
  }
  .menu > li > .sub-menu li {
    text-indent: 0;
  }
}

.menu > li:hover .sub-menu {
  visibility: visible;
  opacity: 1;
}

.menu > li.active > a {
  text-decoration: none;
  background: #40474A;
}

.menu > li:hover > a {
  position: relative;
  z-index: 11;
  text-decoration: none;
  background: #5788B3;
}

.menu > li:hover > .sub-menu {
  background: #5788B3;
  box-shadow: 2px 2px 15px rgba(0, 0, 0, 0.3);
  z-index: 10;
}

.menu > li:hover > .sub-menu li > a {
  color: #fff;
  padding: 8px 10px;
}

.menu > li:hover > .sub-menu li > a:hover {
  text-decoration: none;
  background: #7aa1c3;
}

.placeholder {
  min-height: 40px;
}

.heading .col {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.heading .vloga {
  font-weight: bold;
  background: #EEF2F5;
  padding: 5px 15px;
  border-radius: 3px;
  vertical-align: text-bottom;
  margin: 5px 0 0 20px;
}

.heading h1 {
  font-size: 1.5rem;
  display: flex;
  font-weight: 600;
  align-self: center;
  margin-bottom: 0;
  color: #fff;
  margin-bottom: 1rem;
  display: inline-block;
}

.heading h1.icon {
  background-size: 30px;
  padding-left: 3rem;
}

@media screen and (min-width: 48em) {
  .heading h1 {
    margin-top: 1rem;
    margin-bottom: 1rem;
    font-size: 2rem;
  }
  .heading h1.icon {
    background-size: auto;
    padding-left: 4rem;
  }
}

i[class*='icon'] {
  display: inline-block;
  vertical-align: sub;
  width: 20px;
  height: 21px;
}

#mobile-icon {
  background: #EC1F29;
  position: absolute;
  padding: 8px 12px;
  top: 0px;
  right: 0px;
  color: white;
  font-size: 2rem;
  margin-right: 0;
  z-index: 501;
}

@media screen and (min-width: 48em) {
  #mobile-icon {
    display: none;
  }
}

#mobile-icon .line {
  width: 30px;
  height: 4px;
  background-color: #ecf0f1;
  display: block;
  margin: 5px auto;
  transition: all 0.3s ease-in-out;
}

#mobile-icon.opened .line:nth-child(2) {
  opacity: 0;
}

#mobile-icon.opened .line:nth-child(1) {
  transform: translateY(11px) rotate(45deg);
}

#mobile-icon.opened .line:nth-child(3) {
  transform: translateY(-7px) rotate(-45deg);
}

#mobile-icon:hover {
  cursor: pointer;
}

.body {
  margin-top: 3rem;
}

.body .blok {
  margin-bottom: 3rem;
}

.body .blok h3 {
  color: #5788B3;
}

.body .blok a {
  color: #40474A;
  text-decoration: none;
}

.body .blok a:hover {
  color: #5788B3;
}

.body .blok.bg--grey {
  color: #fff;
  background: #5788B3;
}

.body .blok.bg--grey h3 {
  color: #fff;
}

.body .blok p {
  min-height: 3rem;
}

.body .blok img {
  max-width: 100%;
  margin-bottom: 1rem;
}

.body .blok ul {
  margin-left: 0;
  margin-bottom: 0;
  padding: 0;
}

.body .blok label {
  width: 3rem;
}

.body .blok li {
  list-style: none;
  padding-left: 2rem;
  margin-bottom: .5rem;
}

dl {
  margin-bottom: 0;
}

dl dt, dl dd {
  display: inline-block;
  margin-bottom: 0;
  margin-top: .5rem;
}

dl dt {
  min-width: 5rem;
}

.container {
  max-width: 1400px;
}

.table-collapsable tbody tr:nth-of-type(4n+1) {
  background-color: rgba(0, 0, 0, 0.05);
}

.table-collapsable tbody > tr.hiding > td {
  padding: 0;
}

.table-collapsable tbody > tr.hiding .table {
  width: auto;
  margin-bottom: 0;
}

.table-collapsable tbody > tr.hiding .table thead {
  background: #c0d3e3;
}

.table {
  border-bottom: 3px solid #dee2e6;
  margin-bottom: 2rem;
}

.table a {
  text-decoration: none;
}

.table thead {
  background: #5788B3;
}

.table thead th {
  color: #fff;
  border-bottom: 0;
  padding: 0.6rem;
}

.table thead a {
  color: #fff;
}

.table thead a:hover {
  color: #EEF2F5;
  text-decoration: underline;
}

.table tbody > tr:not(.hiding):hover {
  background: #dee6ec;
}

.table tbody td {
  padding: 0.6rem;
}

.table .upravljanje {
  text-align: right;
}

.table .upravljanje .ikona {
  margin-left: .5rem;
  padding: 0.2rem 0;
}

.table .upravljanje .ikona .table-icon {
  fill: #40474A;
}

.table .upravljanje .ikona:hover .table-icon {
  fill: #40474A;
}

.collapser {
  cursor: pointer;
  position: absolute;
  height: 35px;
  width: 97%;
}

.collapser:before {
  background-color: #EEF2F5;
  border: 1px solid #5788B3;
  border-radius: 4px;
  color: #5788B3;
  content: "\2212";
  display: inline-flex;
  font-weight: bold;
  line-height: .6;
  text-align: center;
  padding: 1px 2px 4px 3px;
}

.collapser.collapsed:before {
  content: "+";
}

.table-footer td {
  background-color: white;
}

.pager {
  margin-top: 10px;
}

.pager tbody tr {
  background-color: transparent !important;
}

.pager tbody td {
  border: none;
  background: transparent;
  padding: 0 8px 0 0;
}

.pager tbody td span, .pager tbody td a {
  background: #fff;
  display: block;
  padding: 3px 10px;
  border: 1px solid #EEF2F5;
}

.pager tbody td a {
  cursor: pointer;
}

.pager tbody td a:hover {
  background: #EEF2F5;
}

.pager tbody td span {
  border-color: #EC1F29;
  background: #EC1F29;
  color: #fff;
}

.subrow {
  padding: 20px;
}

.row-menu {
  list-style: none;
  padding: 0;
  margin: 0;
}

.row-menu li {
  display: flex;
  line-height: 1.8;
}

.row-menu .label {
  flex-grow: 1;
  font-weight: bold;
}

.row-menu .controls {
  display: flex;
}

.row-menu .controls a {
  display: block;
  margin: 0 5px;
}

.tag {
  color: #fff;
  font-size: 13px;
  font-weight: bold;
  display: inline-block;
  border-radius: 5px;
  padding: 3px 10px;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.35);
}

.tag.red {
  background-color: #E05353;
}

.tag.green {
  background-color: #83BC39;
}

.tag.yellow {
  background-color: #DEA937;
}

.panel {
  margin-top: 2rem;
}

.panel.panel-default .panel-heading {
  background-color: #5788B3;
}

.panel.panel-grey .panel-heading {
  background-color: #40474A;
}

.panel .panel-heading {
  color: #fff;
  padding: 7px 15px;
  font-size: 1.3rem;
}

.panel .panel-body {
  background-color: #EEF2F5;
  padding: 10px 15px;
}

.user-details h3 {
  background: url("/images/icon-user.svg") no-repeat top right;
}

.notifications h3 {
  background: url("/images/icon-info.svg") no-repeat top right;
}

.list-icon--list {
  background: url("/images/list.svg") no-repeat left center;
}

.list-icon--arrow {
  background: url("/images/arrow.svg") no-repeat left center;
}

.list-icon--add {
  background: url("/images/add.svg") no-repeat left center;
}

.list-icon--exit {
  background: url("/images/exit.svg") no-repeat left center;
}

.icon {
  padding-left: 4rem;
}

.icon.small-gap {
  padding-left: 2.5rem;
}

.icon-stranke {
  background: url("/images/icon-stranke_big.svg") no-repeat left center;
}

.icon-objekti {
  background: url("/images/icon-objekti-big.svg") no-repeat left center;
}

.icon-dash {
  background: url("/images/icon-dashboard.svg") no-repeat left center;
}

.icon-vozila {
  background: url("/images/icon-vozila.svg") no-repeat left center;
  padding-left: 5rem;
}

.icon-admin {
  background: url("/images/icon-admin.svg") no-repeat left center;
}

.icon-dodaj-stranko {
  background: url("/images/icon-add-user.svg") no-repeat;
}

.icon-dodaj {
  background: url("/images/icon-add.svg") no-repeat left center;
}

.btn .icon-dodaj {
  background-size: 60%;
}

.icon-collapser {
  background: url("/images/icon-collapse.svg") no-repeat left center;
}

.collapsed .icon-collapser {
  background: url("/images/icon-add.svg") no-repeat left center;
}

.icon-collapser-gray {
  background: url("/images/icon-collapse-gray.svg") no-repeat left center;
}

.collapsed .icon-collapser-gray {
  background: url("/images/icon-add-gray.svg") no-repeat left center;
}

.icon-nazaj {
  background: url("/images/icon-nazaj.svg") no-repeat left center;
}

.btn .icon-nazaj {
  padding-right: 1.5rem;
}

.icon-opozorilo {
  background: url("/images/icon-warning.svg") no-repeat left center;
}

.icon-dodaj-objekt {
  background: url("/images/icon-objekt.svg") no-repeat;
}

.icon-odjava {
  background: url("/images/exit-white.svg") no-repeat;
}

.icon-nastavitve {
  background: url("/images/ico-settings.svg") no-repeat;
}

.icon-odjava-red {
  background: url("/images/exit.svg") no-repeat;
}

.icon-preklici {
  background: url("/images/icon-close.svg") no-repeat left center;
}

.icon-preklici-green {
  background: url("/images/icon_close_green.svg") no-repeat left center;
}

.icon-shrani {
  background: url("/images/icon_save_white.svg") no-repeat left center;
}

.icon-skode {
  background: url("/images/icon-skode.svg") no-repeat left center;
}

.icon-podatki {
  background: url("/images/icon-podatki.svg") no-repeat left center;
}

.icon-dokumentacija {
  background: url("/images/icon-dokumentacija.svg") no-repeat left center;
}

.icon-statistika {
  background: url("/images/icon-statistika.svg") no-repeat left center;
}

.help {
  display: inline-block;
  margin-left: 10px;
  cursor: pointer;
}

.icon-oddaj {
  background: url("/images/icon-oddaj.svg") no-repeat left center;
}

.icon-zakljuci {
  background: url("/images/icon-zakljuci.svg") no-repeat left center;
}

.icon-izbrisi {
  background: url("/images/icon-izbrisi.svg") no-repeat left center;
}

.icon-pdf {
  background: url("/images/icon-pdf.svg") no-repeat left center;
}

.icon-zakleni {
  background: url("/images/icon-zakleni.svg") no-repeat left center;
}

.icon-odkleni {
  background: url("/images/icon-odkleni.svg") no-repeat left center;
}

.required {
  color: #EC1F29;
  font-weight: 600;
}

.form-group {
  margin-bottom: .6rem;
}

.form-control {
  max-height: 38px;
  font-family: 'Titillium Web', sans-serif;
}

textarea.form-control {
  max-height: inherit;
}

.field-output {
  font-weight: 600;
  line-height: 1.3rem;
  color: #5788B3;
}

.has-error.checkbox label,
.has-error.checkbox-inline label,
.has-error.radio label,
.has-error.radio-inline label,
.has-error .checkbox,
.has-error .checkbox-inline,
.has-error .control-label,
.has-error .help-block,
.has-error .radio,
.has-error .radio-inline {
  color: #EC1F29;
}

.has-error .form-control {
  border-color: #EC1F29;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
}

.form-check-inline .form-check-input {
  margin-right: .6rem;
}

.form-control:focus {
  border-color: #EC1F29;
}

.datepicker-panel > ul > li.picked, .datepicker-panel > ul > li.picked:hover, .datepicker-panel > ul > li.picked.highlighted {
  background-color: #e3ebf3;
  color: #5788B3;
  font-weight: bold;
}

.datepicker-panel > ul > li.highlighted {
  color: #EC1F29;
  font-weight: bold;
  background: none;
}

.datepicker-panel > ul > li:hover {
  background-color: #e3ebf3;
}

.preview-mode .panel-body h4 {
  border-bottom: 2px solid #c0c6c9;
  margin-top: .3rem;
  margin-bottom: .6rem;
  padding-bottom: .8rem;
}

.preview-mode .panel-body .help {
  display: none;
}

.preview-mode .control-label {
  font-weight: 600;
}

.preview-mode .form-group.row {
  align-items: baseline;
}

.preview-mode .form-control {
  background: white;
}

.preview-mode span.required {
  display: none;
}

.preview-mode select::-ms-expand {
  display: none;
}

.preview-mode select {
  -webkit-appearance: none;
  -moz-appearance: none;
  text-indent: 1px;
  text-overflow: '';
}

.chosen-container-multi,
.chosen-container {
  width: 100% !important;
}

.chosen-container-multi .chosen-choices,
.chosen-container-multi .chosen-single,
.chosen-container .chosen-choices,
.chosen-container .chosen-single {
  display: block;
  background: white;
  padding: .28rem .75rem;
  font-size: 1rem;
  line-height: 1.4;
  color: #495057;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  box-shadow: none;
  border-radius: .25rem;
  transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}

.chosen-container-multi .chosen-choices div b,
.chosen-container-multi .chosen-single div b,
.chosen-container .chosen-choices div b,
.chosen-container .chosen-single div b {
  background-position: 0 9px;
}

.chosen-container-multi .chosen-choices .search-choice,
.chosen-container-multi .chosen-single .search-choice,
.chosen-container .chosen-choices .search-choice,
.chosen-container .chosen-single .search-choice {
  font-size: 14px;
}

.chosen-container-multi .chosen-single,
.chosen-container .chosen-single {
  height: 38px;
}

.chosen-container-multi .chosen-single span,
.chosen-container .chosen-single span {
  line-height: 1.8;
}

.chosen-container-active .chosen-choices {
  border-color: #EC1F29;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.input-group-append .btn {
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
}

input[type="file"] {
  font-size: 13px;
  padding-left: 7px;
}

html {
  height: 100%;
}

body {
  font-family: 'Titillium Web', sans-serif;
  color: #40474A;
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow-x: hidden;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  body {
    display: block;
  }
}

/* Custom page header */
.header {
  padding-bottom: 1rem;
  border-bottom: .05rem solid #e5e5e5;
}

/* Custom page footer */
.footer {
  margin-top: auto;
  padding: 1.5rem 0;
  color: #8a9599;
  font-size: 14px;
  background-color: #EEF2F5;
}

.footer .footer-menu {
  font-size: 1rem;
  font-weight: bold;
}

.footer .footer-menu a {
  color: #8a9599;
  text-decoration: none;
  margin-left: 1rem;
}

.footer .footer-menu a:hover {
  color: #5788B3;
}

/* Customize container */
.container-narrow > hr {
  margin: 2rem 0;
}

/* Main marketing message and sign up button */
.jumbotron {
  text-align: center;
  border-bottom: .05rem solid #e5e5e5;
}

.jumbotron .btn {
  padding: .75rem 1.5rem;
  font-size: 1.5rem;
}

/* Supporting marketing content */
.marketing {
  margin: 3rem 0;
}

.marketing p + h4 {
  margin-top: 1.5rem;
}

/* Responsive: Portrait tablets and up */
@media screen and (min-width: 48em) {
  /* Remove the padding we set earlier */
  .header,
  .marketing,
  .footer {
    padding-right: 0;
    padding-left: 0;
  }
  /* Space out the masthead */
  .header {
    margin-bottom: 2rem;
  }
  /* Remove the bottom border on the jumbotron for visual effect */
  .jumbotron {
    border-bottom: 0;
  }
}

a {
  color: #5788B3;
  text-decoration: underline;
  transition: color ease .2s, background-color ease .2s, opacity ease .2s;
}

a:hover {
  color: #EC1F29;
  text-decoration: none;
}
