.btn {
  border-radius: 0;
  text-decoration: none;
  border: none;
  
  i {
    padding-right: 2rem;
  }

  &:focus {
    box-shadow: none;
  }
}

.dropdown-toggle {
  border: 1px solid #ced4da;
  border-radius: .25rem;
}

.bootstrap-select.btn-group .dropdown-menu {
  box-shadow: 2px 5px 15px rgba(0,0,0,0.3)
}

.dropdown-menu.show .dropdown-menu {
  display: block;

  li {
    a {
      display: block;
      padding: 2px 10px;
    }

    &.selected a {
      color: $primary;
    }
  }
}

.margin-left {
  margin-left: 1rem;
}

.btn-default {
  background: $primary;
  color: $white;

  &:focus {
    color: $white;
  }

  &:hover {
    color: $white;
    background-color: darken($primary, 10%);
  }
}

.btn-secondary {
  background: $secondary;
  color: $white;

  &:hover {
    color: $white;
    background-color: darken($secondary, 10%);
  }
}

.btn-inverted {
  background: $white;
  border: 1px solid $primary;
  color: $primary;

  &:focus {
    color: $secondary;
  }

  &:hover {
    background-color: $light;
    color: $primary;
  }
}

.btn-secondary-inverted {
  background: $white;
  border: 1px solid $secondary;
  color: $secondary;

  &:hover {
    background-color: $light-grey;
    color: $secondary;
  }
}

.pointer {
  cursor: pointer;
}