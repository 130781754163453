h3 {
  font-size: 1.5rem;
  font-weight: 600;
  color: $primary;
}

h4 {
  font-size: 1.3rem;
  font-weight: 600;
  color: $grey;
  margin: 1rem 0;
}
