.table-collapsable {
  tbody {
    tr:nth-of-type(4n+1) {
      background-color: rgba(0,0,0,.05);
    }

    > tr.hiding {
      > td {
        padding: 0;
      }

       .table {
        width: auto;
        margin-bottom: 0;

        thead {
          background: lighten($secondary, 30%);
        }
      }
    }
  }
}

.table {
  border-bottom: 3px solid #dee2e6;
  margin-bottom: 2rem;
  
  a {
    text-decoration: none;
  }

  thead {
    background: $secondary;

    th {
      color: $white;
      border-bottom: 0;
      padding: 0.6rem;
    }

    a {
      color: $white;

      &:hover {
        color: $highlight;
        text-decoration: underline;
      }
    }
  }

  tbody {
   > tr:not(.hiding):hover {
      background: darken($light, 5%);
    }

    td {
      padding: 0.6rem;
    }
  }

  .upravljanje {
    text-align: right;

    .ikona {
      margin-left: .5rem;
      padding: 0.2rem 0;

      .table-icon {
        fill: $gray;
      }

      &:hover {
        .table-icon {
          fill: $grey;
        }
      }
    }
  }
}

.collapser {
  cursor: pointer;
  position: absolute;
  height: 35px;
  width: 97%;
}

.collapser:before {
  background-color: $light-grey;
  border: 1px solid $secondary;
  border-radius: 4px;
  color: $secondary;
  content: "\2212";
  display: inline-flex;
  font-weight: bold;
  line-height: .6;
  text-align: center;
  padding: 1px 2px 4px 3px;
}

.collapser.collapsed:before {
  content: "+";
}

.table-footer {
  td {
    background-color: white;
  }
}

.pager {
  margin-top: 10px;

  tbody {
    tr {
      background-color: transparent !important;
    }

    td {
      border: none;
      background: transparent;
      padding: 0 8px 0 0;

      span, a {
        background: $white;
        display: block;
        padding: 3px 10px;
        border: 1px solid $light;
      }

      a {
        cursor: pointer;

        &:hover {
          background: $light;
        }
      }

      span {
        border-color: $primary;
        background: $primary;
        color: $white;
      }
    }
  }
}

.subrow {
  padding: 20px;
}

.row-menu {
  list-style: none;
  padding: 0;
  margin: 0;

  li {
    display: flex;
    line-height: 1.8;
  }

  .label {
    flex-grow: 1;
    font-weight: bold;
  }

  .controls {
    display: flex;

    a {
      display: block;
      margin: 0 5px;
    }
  }
}


.tag {
  color: $white;
  font-size: 13px;
  font-weight: bold;
  display: inline-block;
  border-radius: 5px;
  padding: 3px 10px;
  text-shadow: 1px 1px 1px rgba(0,0,0,.35);

  &.red {
    background-color: #E05353;
  }

  &.green {
    background-color: #83BC39;
  }

  &.yellow {
    background-color: #DEA937;
  }
}