// Normalize Styles
@import "variables";
@import "typography";
@import "tools";
@import "buttons";
@import "header";
@import "body";
@import "tables";
@import "components";
@import "forms";

html {
  height: 100%;
}

body {
  font-family: 'Titillium Web', sans-serif;
  color: $base;
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow-x: hidden;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    display: block;
  }
}

/* Custom page header */
.header {
  padding-bottom: 1rem;
  border-bottom: .05rem solid #e5e5e5;
}

/* Custom page footer */
.footer {
  margin-top: auto;
  padding: 1.5rem 0;
  color: lighten($base, 30%);
  font-size: 14px;
  background-color: $light;

  .footer-menu {
    font-size: 1rem;
    font-weight: bold;

    a {
      color: lighten($base, 30%);
      text-decoration: none;
      margin-left: 1rem;

      &:hover {
        color: $secondary
      }
    }
  }
}

/* Customize container */
.container-narrow > hr {
  margin: 2rem 0;
}

/* Main marketing message and sign up button */
.jumbotron {
  text-align: center;
  border-bottom: .05rem solid #e5e5e5;
}
.jumbotron .btn {
  padding: .75rem 1.5rem;
  font-size: 1.5rem;
}

/* Supporting marketing content */
.marketing {
  margin: 3rem 0;
}

.marketing p + h4 {
  margin-top: 1.5rem;
}

/* Responsive: Portrait tablets and up */
@media screen and (min-width: 48em) {
  /* Remove the padding we set earlier */
  .header,
  .marketing,
  .footer {
    padding-right: 0;
    padding-left: 0;
  }
  /* Space out the masthead */
  .header {
    margin-bottom: 2rem;
  }
  /* Remove the bottom border on the jumbotron for visual effect */
  .jumbotron {
    border-bottom: 0;
  }
}

a {
  color: $secondary;
  text-decoration: underline;
  transition: color ease .2s, background-color ease .2s, opacity ease .2s;

  &:hover {
    color: $primary;
    text-decoration: none;
  }
}
