.panel {
  margin-top: 2rem;

  &.panel-default {
    .panel-heading {
      background-color: $secondary;
    }
  }

  &.panel-grey {
    .panel-heading {
      background-color: $grey;
    }
  }

  .panel-heading {
    color: $white;
    padding: 7px 15px;
    font-size: 1.3rem;
  }

  .panel-body {
    background-color: $light-grey;
      padding: 10px 15px;
  }
}

.user-details h3 {
  background: url('/images/icon-user.svg') no-repeat top right;
}

.notifications h3 {
  background: url('/images/icon-info.svg') no-repeat top right;
}

.list-icon--list {
  background: url('/images/list.svg') no-repeat left center;
}

.list-icon--arrow {
  background: url('/images/arrow.svg') no-repeat left center;
}

.list-icon--add {
  background: url('/images/add.svg') no-repeat left center;
}

.list-icon--exit {
  background: url('/images/exit.svg') no-repeat left center;
}

.icon {
  padding-left: 4rem;

  &.small-gap {
    padding-left: 2.5rem;
  }
}

.icon-stranke {
  background: url('/images/icon-stranke_big.svg') no-repeat left center;
}

.icon-objekti {
  background: url('/images/icon-objekti-big.svg') no-repeat left center;
}

.icon-dash {
  background: url('/images/icon-dashboard.svg') no-repeat left center;
}

.icon-vozila {
  background: url('/images/icon-vozila.svg') no-repeat left center;
  padding-left: 5rem;
}

.icon-admin {
  background: url('/images/icon-admin.svg') no-repeat left center;
}

.icon-dodaj-stranko {
  background: url('/images/icon-add-user.svg') no-repeat;
}

.icon-dodaj {
  background: url('/images/icon-add.svg') no-repeat left center;

  .btn & {
    background-size: 60%;
  }
}

.icon-collapser {
  background: url('/images/icon-collapse.svg') no-repeat left center;

  .collapsed & {
    background: url('/images/icon-add.svg') no-repeat left center;
  }
}

.icon-collapser-gray {
  background: url('/images/icon-collapse-gray.svg') no-repeat left center;

  .collapsed & {
    background: url('/images/icon-add-gray.svg') no-repeat left center;
  }
}

.icon-nazaj {
  background: url('/images/icon-nazaj.svg') no-repeat left center;

  .btn & {
    padding-right: 1.5rem;
  }
}

.icon-opozorilo {
  background: url('/images/icon-warning.svg') no-repeat left center;
}

.icon-dodaj-objekt {
  background: url('/images/icon-objekt.svg') no-repeat;
}

.icon-odjava {
  background: url('/images/exit-white.svg') no-repeat;
}

.icon-nastavitve {
  background: url('/images/ico-settings.svg') no-repeat;
}

.icon-odjava-red {
  background: url('/images/exit.svg') no-repeat;
}

.icon-preklici {
  background: url('/images/icon-close.svg') no-repeat left center;
}

.icon-preklici-green {
  background: url('/images/icon_close_green.svg') no-repeat left center;
}

.icon-shrani {
  background: url('/images/icon_save_white.svg') no-repeat left center;
}

.icon-skode {
  background: url('/images/icon-skode.svg') no-repeat left center;
}

.icon-podatki {
  background: url("/images/icon-podatki.svg") no-repeat left center;
}

.icon-dokumentacija {
  background: url("/images/icon-dokumentacija.svg") no-repeat left center;
}

.icon-statistika {
  background: url("/images/icon-statistika.svg") no-repeat left center;
}

.help {
  display: inline-block;
  margin-left: 10px;
  cursor: pointer;
}

.icon-oddaj {
  background: url("/images/icon-oddaj.svg") no-repeat left center;
}
.icon-zakljuci {
  background: url("/images/icon-zakljuci.svg") no-repeat left center;
}
.icon-izbrisi {
  background: url("/images/icon-izbrisi.svg") no-repeat left center;
}
.icon-pdf {
  background: url("/images/icon-pdf.svg") no-repeat left center;
}
.icon-zakleni {
  background: url("/images/icon-zakleni.svg") no-repeat left center;
}
.icon-odkleni {
  background: url("/images/icon-odkleni.svg") no-repeat left center;
}