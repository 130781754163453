
.margin-top {
  margin-top: 2rem;
}

.skip-field {
  @media screen and (min-width: 48em) {
    margin-top: 3.2rem;
  }
}

.offset-top {
  @media screen and (min-width: 48em) {
    margin-top: -5rem;
  }
}

.display-block {
  display: block;
}

.padding {
  padding: 1.2rem 1.5rem;
}

.padding-v {
  padding: 1.5rem 0;
}

.tooltip {
  font-size: 0.8rem;
}

.modal {
  .modal-content {
    border: 1px solid $primary;
    border-radius: 0;
  }

  .modal-header {
    background-color: $primary;
    padding: 5px 15px;
    color: $white;
    font-weight: 600;
    border-bottom: 0;
    border-radius: 0;

    .close {
      color: $white;
      font-weight: 300;
    }

    .icon {
      padding-left: 2.5rem;
    }

    i[class*='icon'] {
      background-size: 66%;
      padding-right: 0;
      width: 26px;
      height: 26px;
    }
  }

  .modal-body {
    padding: 2rem 2rem .5rem;
  }

  @media (min-width: 576px) {
    .modal-dialog {
        max-width: 600px;
        margin: 15% auto 0 auto;
    }
  }

  .modal-footer {
    border: none;
    >:not(:first-child) {
      margin-left: 1rem;
    }
  }
}

.light {
  line-height: .5;
  padding: 0 5px 0 15px;
  color: $warning;

  &.on {
    color: $green;
  }

  &:before {
    font-size: 1.3rem;
    content: "\25CF";
  }
}

.alert .form-group:last-of-type {
  margin-bottom: 0;
}

.pager-listview td {
  padding: 0.9rem 0.6rem !important;
}

.pager-listview:hover {
  background-color: transparent !important;
}

.pager-listview-item,
.pager-listview-item-selected {
  background: #fff;
  padding: 3px 10px;
  margin: 0 8px 0 0;
  border: 1px solid #EEF2F5;
}

.pager-listview-item a {
  cursor: pointer;
}

.pager-listview-item:hover {
  background: #EEF2F5;
}

.pager-listview,
.pager-listview:hover {
  background-color: transparent !important;
}

.pager-listview-item-selected {
  border-color: #EC1F29;
  background: #EC1F29;
  color: #fff;
}

.hover-alert-bottom {
  z-index: 1000;
  position: fixed;
  bottom: 30px;
  left: 50%;
  transform: translateX(-50%);
}